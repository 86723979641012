import React from "react";
import "./styles/global.sass";
import { ThemeProvider } from "@mui/material";
import { theme } from "./styles/theme";
import Footer from "../src/layouts/UI/Footer";
import { Navbar } from "./layouts/UI/Navbar/Navbar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Home";
import Course from "./Course";
import { Suspense } from "react";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { Helmet } from "react-helmet";
import ComingSoon from "./ComingSoon";

Amplify.configure(awsExports);

const MyApp: React.FC = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Techcenture: Academy of success</title>
        <meta
          name="description"
          content="Academy is one powerful learning platform that combines all the tools and resources for the professional development and gaining practical experience"
        />
        <link rel="canonical" href="http://techcenture.com" />
      </Helmet>
      <React.StrictMode>
        <ComingSoon/>
      </React.StrictMode>

      {/* <Suspense>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/course/:type" element={<Course />} />
              <Route path="/course" element={<Course />} />
            </Routes>
            <Footer />
          </ThemeProvider>
        </BrowserRouter>
      </Suspense> */}
    </>
  );
};

export default MyApp;
