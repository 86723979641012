import { Facebook, Instagram } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { Clock } from "./components/Clock/clock";
import AnalogClock from "./components/Clock/AnalogClock";

function ComingSoon() {
  return (
    <div className="App">
      <div className="mainlayout">
        <div className="container">
          <div style={{ color: "white", display: "flex" }}>
            <div
              style={{
                background: "#ffffff36",
                padding: "40px 10px 10px 10px",
              }}
            >
              <img src="/logo-new.png" alt="logo" />
            </div>
          </div>
          <div>
            {/* <Clock/> */}
            <AnalogClock />
            <div
              style={{
                display: "flex",
                flexDirection: 'column',
                alignItems: 'center',
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: 20,
                transform: "translate(-50%, -50%)"
              }}
            >
              <div style={{
    fontFamily: "sans-serif",
    "color": "#cbc8c8",
    marginBottom: 20,
}}>OUR NEW SITE IS</div>
              <div style={{
    fontFamily: "sans-serif",
    "color": "#cbc8c8",
    fontSize: "70px",
}}>COMING</div>
<div style={{
fontFamily: "sans-serif",
"color": "#cbc8c8",
fontSize: "70px",
}}>SOON</div>
              <div style={{
    fontFamily: "sans-serif",
    "color": "#cbc8c8",
    marginTop: 50
}}>STAY TUNED!</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComingSoon;
