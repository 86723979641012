import React, { useEffect, useState } from "react";
import "./AnalogClock.css";

const AnalogClock: React.FC = () => {
  const [time, setTime] = useState<Date>(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const hour = time.getHours();
  const minute = time.getMinutes();
  // const second = time.getSeconds();

  const hourDegrees = (hour % 12) * 30 + minute * 0.5 + 90; // 360 / 12 = 30
  const minuteDegrees = minute * 6 + 90; // 360 / 60 = 6
  // const secondDegrees = second * 6 + 90; // 360 / 60 = 6

  return (
    <div className="clock">
      <div className="clock-face">
        <div
          className="hand hour-hand"
          // style={{ transform: `rotate(180deg)` }}
          style={{ transform: `rotate(${hourDegrees}deg)` }}
        />
        <div
          className="hand minute-hand"
          style={{ transform: `rotate(${minuteDegrees}deg)` }}
        />
        {/* <div
          className="hand second-hand"
          style={{ transform: `rotate(${secondDegrees}deg)` }}
        /> */}
        {[...Array(12)].map((_, index) => (
          <div
            key={index}
            className="clock-stick"
            style={{ transform: `rotate(${index * 30}deg)` }}
          >
            <div className="stick" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AnalogClock;
